<template>
    <div>
        <img style="width: 100%" src="https://cdn.mekoomall.com/static/pc/manager/墨库商城平台对用户交易纠纷处理的机制或方案.jpg" alt="">
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>